import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar, faCalendarAlt, faUsers, faUserPlus,
  faFileAlt, faComments, faSearch, faBell, faPlus,
  faBars, faClock, faEnvelope, faCheckCircle, faFilter,
  faSort, faEllipsisH, faChild, faTooth, faUser,
  faCog, faSignOutAlt, faTimes, faCamera, faLock
} from '@fortawesome/free-solid-svg-icons';
import './crm-styles.css';
import './additions.css';
import './registration-styles.css';
import PatientRegistrationForm from './components/PatientRegistrationForm';

function App() {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  // Estados para la barra de búsqueda
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);
  
  // Estados para las notificaciones
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsRef = useRef(null);
  
  // Estados para el menú de usuario
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);
  
  // Estados para el modal de perfil
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: 1,
    name: 'Dr. Rodríguez',
    email: 'dr.rodriguez@odontokidz.com',
    phone: '555-1234',
    role: 'admin',
    specialty: 'Odontopediatría',
    avatar: 'DR'
  });
  
  // Lista de usuarios predefinidos
  const usersList = [
    {
      id: 1,
      name: 'Dr. Rodríguez',
      email: 'dr.rodriguez@odontokidz.com',
      phone: '555-1234',
      role: 'admin',
      specialty: 'Odontopediatría',
      avatar: 'DR'
    },
    {
      id: 2,
      name: 'Dra. Sánchez',
      email: 'dra.sanchez@odontokidz.com',
      phone: '555-5678',
      role: 'doctor',
      specialty: 'Ortodoncia',
      avatar: 'DS'
    },
    {
      id: 3,
      name: 'María López',
      email: 'maria.lopez@odontokidz.com',
      phone: '555-9012',
      role: 'operator',
      specialty: '',
      avatar: 'ML'
    }
  ];
  
  // Estado del formulario de perfil
  const [profileForm, setProfileForm] = useState({...currentUser});
  const [selectedUser, setSelectedUser] = useState(currentUser.id);

  // Datos de ejemplo
  const appointments = [
    { id: 1, patient: 'Lucía Martínez', age: '8 años', time: '09:00 AM', doctor: 'Dra. Sánchez', treatment: 'Limpieza dental' },
    { id: 2, patient: 'Manuel Gómez', age: '6 años', time: '10:30 AM', doctor: 'Dr. Ramírez', treatment: 'Revisión ortodoncia' },
    { id: 3, patient: 'Sara López', age: '5 años', time: '12:00 PM', doctor: 'Dra. Sánchez', treatment: 'Empaste' },
    { id: 4, patient: 'Diego Flores', age: '9 años', time: '03:15 PM', doctor: 'Dr. Morales', treatment: 'Extracción' }
  ];

  const reminders = [
    { id: 1, patient: 'Ana Torres', status: 'Confirmado', method: 'WhatsApp' },
    { id: 2, patient: 'Carlos Mendoza', status: 'Pendiente', method: 'Email' },
    { id: 3, patient: 'Valeria Díaz', status: 'Confirmado', method: 'WhatsApp' }
  ];

  const patients = [
    { id: 1, name: 'Lucía Martínez', age: 8, parent: 'María Martínez', phone: '555-1234', lastVisit: '15/03/2025' },
    { id: 2, name: 'Manuel Gómez', age: 6, parent: 'Pedro Gómez', phone: '555-5678', lastVisit: '20/03/2025' },
    { id: 3, name: 'Sara López', age: 5, parent: 'Laura López', phone: '555-9012', lastVisit: '22/03/2025' },
    { id: 4, name: 'Diego Flores', age: 9, parent: 'Roberto Flores', phone: '555-3456', lastVisit: '18/03/2025' },
  ];
  
  // Datos de ejemplo para notificaciones
  const notifications = [
    { id: 1, type: 'appointment', message: 'Cita con Lucía Martínez en 30 minutos', time: '09:30 AM', read: false },
    { id: 2, type: 'reminder', message: 'Recordar llamar a Carlos Mendoza para confirmar cita', time: 'Ayer', read: true },
    { id: 3, type: 'payment', message: 'Pago recibido de Sara López', time: 'Ayer', read: true }
  ];

  // Efecto para búsqueda de pacientes
  useEffect(() => {
    if (searchTerm.length > 2) {
      // Filtrar pacientes que coincidan con el término de búsqueda
      const results = patients.filter(patient => 
        patient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phone.includes(searchTerm)
      );
      setSearchResults(results);
      setShowResults(true);
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  }, [searchTerm, patients]);

  // Actualizar el formulario cuando cambia el usuario seleccionado
  useEffect(() => {
    const selectedUserData = usersList.find(user => user.id === selectedUser);
    if (selectedUserData) {
      setProfileForm({...selectedUserData});
    }
  }, [selectedUser, usersList]);

  // Cerrar resultados de búsqueda cuando se hace clic fuera
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // Cerrar notificaciones cuando se hace clic fuera
  useEffect(() => {
    function handleClickOutside(event) {
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // Cerrar menú de usuario cuando se hace clic fuera
  useEffect(() => {
    function handleClickOutside(event) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleMenuClick = (tab) => {
    setActiveTab(tab);
    setSidebarOpen(false);
  };
  
  const handleProfileSave = () => {
    // Actualizar el usuario actual con los datos del formulario
    setCurrentUser({...profileForm});
    
    // Si el usuario seleccionó otro usuario, cambiar al nuevo usuario
    if (selectedUser !== currentUser.id) {
      const newUser = usersList.find(user => user.id === selectedUser);
      if (newUser) {
        setCurrentUser({...newUser});
      }
    }
    
    // Cerrar el modal
    setShowProfileModal(false);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const renderDashboard = () => (
    <div className="page-container">
      <h1 className="page-title">Dashboard</h1>
      <p className="page-subtitle">Bienvenido al CRM de OdontoKidz. Aquí tienes un resumen de la actividad.</p>

      <div className="stats-grid">
        <div className="stat-card">
          <div className="icon-container icon-blue">
            <FontAwesomeIcon icon={faChild} />
          </div>
          <div className="stat-info">
            <h3>Pacientes Nuevos</h3>
            <p className="value">24</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="icon-container icon-green">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
          <div className="stat-info">
            <h3>Citas Hoy</h3>
            <p className="value">15</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="icon-container icon-orange">
            <FontAwesomeIcon icon={faTooth} />
          </div>
          <div className="stat-info">
            <h3>Procedimientos Pendientes</h3>
            <p className="value">8</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="icon-container icon-purple">
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          <div className="stat-info">
            <h3>Tasa de Ocupación</h3>
            <p className="value">85%</p>
          </div>
        </div>
      </div>

      <div className="section-header">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faClock} />
          Próximas Citas
        </h2>
        <div style={{ display: 'flex', gap: '8px' }}>
          <button className="btn btn-outline">
            <FontAwesomeIcon icon={faFilter} />
            Filtrar
          </button>
          <button className="btn btn-outline">
            <FontAwesomeIcon icon={faSort} />
            Ordenar
          </button>
          <button className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />
            Nueva Cita
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Paciente</th>
              <th>Edad</th>
              <th>Hora</th>
              <th>Doctor</th>
              <th>Tratamiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td>{appointment.patient}</td>
                <td>{appointment.age}</td>
                <td>{appointment.time}</td>
                <td>{appointment.doctor}</td>
                <td>{appointment.treatment}</td>
                <td>
                  <div className="action-btns">
                    <button className="btn-view">Ver</button>
                    <button className="btn-edit">Editar</button>
                    <button className="btn-edit" style={{ padding: '6px' }}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="section-header">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faBell} />
          Estado de Recordatorios
        </h2>
        <button className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} />
          Nuevo Recordatorio
        </button>
      </div>

      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Paciente</th>
              <th>Estado</th>
              <th>Medio</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {reminders.map((reminder) => (
              <tr key={reminder.id}>
                <td>{reminder.patient}</td>
                <td>
                  <span className={`status-pill ${reminder.status === 'Confirmado' ? 'status-confirmed' : 'status-pending'}`}>
                    {reminder.status === 'Confirmado' && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '4px' }} />}
                    {reminder.status}
                  </span>
                </td>
                <td>
                  {reminder.method === 'WhatsApp' ? (
                    <span>WhatsApp</span>
                  ) : (
                    <span><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '4px' }} /> Email</span>
                  )}
                </td>
                <td>
                  <div className="action-btns">
                    <button className="btn-view">Ver</button>
                    <button className="btn-edit">Reenviar</button>
                    <button className="btn-edit" style={{ padding: '6px' }}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderPatients = () => (
    <div className="page-container">
      <h1 className="page-title">Pacientes</h1>
      <p className="page-subtitle">Administra la información de tus pacientes pediátricos.</p>

      <div className="section-header">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faUsers} />
          Directorio de Pacientes
        </h2>
        <div style={{ display: 'flex', gap: '8px' }}>
          <button className="btn btn-outline">
            <FontAwesomeIcon icon={faFilter} />
            Filtrar
          </button>
          <button className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />
            Nuevo Paciente
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Edad</th>
              <th>Padre/Tutor</th>
              <th>Teléfono</th>
              <th>Última Visita</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient) => (
              <tr key={patient.id}>
                <td>{patient.name}</td>
                <td>{patient.age} años</td>
                <td>{patient.parent}</td>
                <td>{patient.phone}</td>
                <td>{patient.lastVisit}</td>
                <td>
                  <div className="action-btns">
                    <button className="btn-view">Ver</button>
                    <button className="btn-edit">Editar</button>
                    <button className="btn-edit" style={{ padding: '6px' }}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderAppointments = () => (
    <div className="page-container">
      <h1 className="page-title">Citas</h1>
      <p className="page-subtitle">Gestiona el calendario de citas de la clínica.</p>

      <div className="section-header">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faCalendarAlt} />
          Calendario de Citas
        </h2>
        <button className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} />
          Nueva Cita
        </button>
      </div>

      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Paciente</th>
              <th>Edad</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Doctor</th>
              <th>Tratamiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td>{appointment.patient}</td>
                <td>{appointment.age}</td>
                <td>26/03/2025</td>
                <td>{appointment.time}</td>
                <td>{appointment.doctor}</td>
                <td>{appointment.treatment}</td>
                <td>
                  <div className="action-btns">
                    <button className="btn-view">Ver</button>
                    <button className="btn-edit">Editar</button>
                    <button className="btn-edit" style={{ padding: '6px' }}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return renderDashboard();
      case 'patients':
        return renderPatients();
      case 'appointments':
        return renderAppointments();
      case 'registration':
      case 'medical-history':
      case 'messages':
        return (
          <div className="page-container">
            <h1 className="page-title">{activeTab.charAt(0).toUpperCase() + activeTab.slice(1).replace(/-/g, ' ')}</h1>
            <p className="page-subtitle">Esta sección está en desarrollo.</p>
            
            <div className="empty-state">
              <FontAwesomeIcon icon={
                activeTab === 'registration' ? faUserPlus :
                activeTab === 'medical-history' ? faFileAlt :
                faComments
              } />
              <h3>Funcionalidad en Desarrollo</h3>
              <p>Estamos trabajando en esta sección para brindarte la mejor experiencia.</p>
              <button className="btn btn-primary">Notificarme cuando esté disponible</button>
            </div>
          </div>
        );
      default:
        return renderDashboard();
    }
  };
  
  // Componente para el Modal del Perfil de Usuario
  const ProfileModal = () => {
    if (!showProfileModal) return null;
    
    const roleLabels = {
      'admin': 'Administrador',
      'doctor': 'Doctor',
      'operator': 'Operador'
    };
    
    return (
      <div className="modal-overlay" onClick={() => setShowProfileModal(false)}>
        <div className="modal-container" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h2>Mi Perfil</h2>
            <button className="modal-close" onClick={() => setShowProfileModal(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div style={{ textAlign: 'center', marginBottom: '24px' }}>
              <div className="avatar" style={{ width: '80px', height: '80px', fontSize: '32px', margin: '0 auto 16px' }}>
                {profileForm.avatar}
              </div>
              <button className="btn btn-outline" style={{ margin: '0 auto' }}>
                <FontAwesomeIcon icon={faCamera} />
                Cambiar foto
              </button>
            </div>
            
            <div className="form-grid">
              <div className="form-group">
                <label className="form-label">Nombre</label>
                <input 
                  type="text" 
                  className="form-control" 
                  name="name" 
                  value={profileForm.name} 
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Correo electrónico</label>
                <input 
                  type="email" 
                  className="form-control" 
                  name="email" 
                  value={profileForm.email} 
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Teléfono</label>
                <input 
                  type="tel" 
                  className="form-control" 
                  name="phone" 
                  value={profileForm.phone} 
                  onChange={handleInputChange}
                />
              </div>
              
              <div className="form-group">
                <label className="form-label">Rol</label>
                <select 
                  className="form-control" 
                  name="role" 
                  value={profileForm.role} 
                  onChange={handleInputChange}
                >
                  <option value="admin">Administrador</option>
                  <option value="doctor">Doctor</option>
                  <option value="operator">Operador</option>
                </select>
              </div>
              
              {profileForm.role === 'doctor' && (
                <div className="form-group" style={{ gridColumn: '1 / -1' }}>
                  <label className="form-label">Especialidad</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    name type="text" 
                   className="form-control" 
                   name="specialty" 
                   value={profileForm.specialty} 
                   onChange={handleInputChange}
                 />
               </div>
             )}
             
             <div className="form-group" style={{ gridColumn: '1 / -1' }}>
               <button className="btn btn-outline" style={{ display: 'flex', alignItems: 'center' }}>
                 <FontAwesomeIcon icon={faLock} style={{ marginRight: '8px' }} />
                 Cambiar contraseña
               </button>
             </div>
           </div>
           
           <div className="user-selector">
             <h3 className="user-selector-title">Cambiar de usuario</h3>
             <div className="user-selector-grid">
               {usersList.map(user => (
                 <div 
                   key={user.id}
                   className={`user-selector-card ${selectedUser === user.id ? 'active' : ''}`}
                   onClick={() => setSelectedUser(user.id)}
                 >
                   <div className="user-selector-avatar">{user.avatar}</div>
                   <p className="user-selector-name">{user.name}</p>
                   <p className="user-selector-role">{roleLabels[user.role]}</p>
                 </div>
               ))}
             </div>
           </div>
         </div>
         <div className="modal-footer">
           <button className="btn btn-outline" onClick={() => setShowProfileModal(false)}>Cancelar</button>
           <button className="btn btn-primary" onClick={handleProfileSave}>Guardar cambios</button>
         </div>
       </div>
     </div>
   );
 };

 return (
   <div className="app-container">
     <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
       <div className="sidebar-header">
         <h1>OdontoKidz</h1>
         <p>CRM</p>
       </div>
       <div className="sidebar-menu">
         <div 
           className={`menu-item ${activeTab === 'dashboard' ? 'active' : ''}`}
           onClick={() => handleMenuClick('dashboard')}
         >
           <FontAwesomeIcon icon={faChartBar} />
           <span>Dashboard</span>
         </div>
         <div 
           className={`menu-item ${activeTab === 'appointments' ? 'active' : ''}`}
           onClick={() => handleMenuClick('appointments')}
         >
           <FontAwesomeIcon icon={faCalendarAlt} />
           <span>Citas</span>
         </div>
         <div 
           className={`menu-item ${activeTab === 'patients' ? 'active' : ''}`}
           onClick={() => handleMenuClick('patients')}
         >
           <FontAwesomeIcon icon={faUsers} />
           <span>Pacientes</span>
         </div>
         <div 
           className={`menu-item ${activeTab === 'registration' ? 'active' : ''}`}
           onClick={() => handleMenuClick('registration')}
         >
           <FontAwesomeIcon icon={faUserPlus} />
           <span>Registro</span>
         </div>
         <div 
           className={`menu-item ${activeTab === 'medical-history' ? 'active' : ''}`}
           onClick={() => handleMenuClick('medical-history')}
         >
           <FontAwesomeIcon icon={faFileAlt} />
           <span>Historial Clínico</span>
         </div>
         <div 
           className={`menu-item ${activeTab === 'messages' ? 'active' : ''}`}
           onClick={() => handleMenuClick('messages')}
         >
           <FontAwesomeIcon icon={faComments} />
           <span>Mensajes</span>
         </div>
       </div>
       <div className="user-profile">
         <div className="avatar">{currentUser.avatar}</div>
         <div className="user-info">
           <p className="name">{currentUser.name}</p>
           <p className="role">{
             currentUser.role === 'admin' ? 'Administrador' :
             currentUser.role === 'doctor' ? 'Doctor' : 'Operador'
           }</p>
         </div>
       </div>
     </div>

     <div className={`menu-overlay ${sidebarOpen ? 'active' : ''}`} onClick={() => setSidebarOpen(false)}></div>

     <div className="main-content">
       <header className="header">
         <div style={{ display: 'flex', alignItems: 'center' }}>
           <button className="mobile-toggle" onClick={toggleSidebar}>
             <FontAwesomeIcon icon={faBars} />
           </button>
           
           {/* Barra de búsqueda con resultados */}
           <div className="search-container" ref={searchRef}>
             <FontAwesomeIcon icon={faSearch} className="search-icon" />
             <input 
               type="text" 
               placeholder="Buscar paciente..." 
               value={searchTerm}
               onChange={(e) => setSearchTerm(e.target.value)}
               onFocus={() => searchTerm.length > 2 && setShowResults(true)}
             />
             
             {showResults && searchResults.length > 0 && (
               <div className="search-results">
                 {searchResults.map(patient => (
                   <div 
                     key={patient.id} 
                     className="search-result-item"
                     onClick={() => {
                       setActiveTab('patients');
                       setSearchTerm('');
                       setShowResults(false);
                     }}
                   >
                     <div className="patient-avatar">
                       {patient.name.charAt(0)}
                     </div>
                     <div className="patient-info">
                       <div className="patient-name">{patient.name}</div>
                       <div className="patient-details">{patient.age} años - Tel: {patient.phone}</div>
                     </div>
                   </div>
                 ))}
               </div>
             )}
             
             {showResults && searchResults.length === 0 && searchTerm.length > 2 && (
               <div className="search-results">
                 <div className="no-results">
                   No se encontraron pacientes que coincidan con "{searchTerm}"
                 </div>
               </div>
             )}
           </div>
         </div>
         <div className="header-actions">
           {/* Panel de notificaciones */}
           <div className="notification-bell" ref={notificationsRef}>
             <div onClick={() => setShowNotifications(!showNotifications)}>
               <FontAwesomeIcon icon={faBell} />
               <span className="notification-badge">3</span>
             </div>
             
             {showNotifications && (
               <div className="notifications-panel">
                 <div className="notifications-header">
                   <h3>Notificaciones</h3>
                   <button className="btn-text">Marcar todas como leídas</button>
                 </div>
                 
                 <div className="notifications-list">
                   {notifications.map(notification => (
                     <div 
                       key={notification.id} 
                       className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                     >
                       <div className="notification-icon">
                         <FontAwesomeIcon 
                           icon={
                             notification.type === 'appointment' ? faCalendarAlt :
                             notification.type === 'reminder' ? faBell :
                             faFileAlt
                           }
                         />
                       </div>
                       <div className="notification-content">
                         <div className="notification-message">{notification.message}</div>
                         <div className="notification-time">{notification.time}</div>
                       </div>
                     </div>
                   ))}
                 </div>
                 
                 <div className="notifications-footer">
                   <button className="btn-text">Ver todas las notificaciones</button>
                 </div>
               </div>
             )}
           </div>
           
           {/* Menú de usuario */}
           <div className="user-menu" ref={userMenuRef} onClick={() => setShowUserMenu(!showUserMenu)}>
             <div className="avatar">{currentUser.avatar}</div>
             <span className="name">{currentUser.name.split(' ')[0]}</span>
             
             {showUserMenu && (
               <div className="user-menu-dropdown">
                 <div className="user-info-header">
                   <div className="avatar">{currentUser.avatar}</div>
                   <div className="name">{currentUser.name}</div>
                   <div className="role">{
                     currentUser.role === 'admin' ? 'Administrador' :
                     currentUser.role === 'doctor' ? 'Doctor' : 'Operador'
                   }</div>
                 </div>
                 
                 <div className="user-menu-items">
                   <div className="user-menu-item" onClick={(e) => {
                     e.stopPropagation();
                     setShowUserMenu(false);
                     setShowProfileModal(true);
                   }}>
                     <FontAwesomeIcon icon={faUser} />
                     <span>Mi perfil</span>
                   </div>
                   
                   <div className="user-menu-item">
                     <FontAwesomeIcon icon={faCog} />
                     <span>Configuración</span>
                   </div>
                   
                   <div className="user-menu-item danger">
                     <FontAwesomeIcon icon={faSignOutAlt} />
                     <span>Cerrar sesión</span>
                   </div>
                 </div>
               </div>
             )}
           </div>
         </div>
       </header>

       <main>
         {renderContent()}
       </main>
       
       {/* Modal de perfil de usuario */}
       <ProfileModal />
     </div>
   </div>
 );
}

export default App;
